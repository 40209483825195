<template>
  <Layout>
    <div v-if="isBusy">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </div>
     <div class="row" v-if="vehicle">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">Vehicles Info</div>
            </div>
            <div class="row">
              <div class="col-6">Plate Category</div>
              <div class="col-6">
                {{ vehicle.plate_category }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Plate Number</div>
              <div class="col-6">
                {{ vehicle.plate_number }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Manufacturing Year</div>
              <div class="col-6">
                {{ vehicle.manufacturing_year }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Sticker Type</div>
              <div class="col-6">
                {{ vehicle.sticker_type }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Rating</div>
              <div class="col-6">
                {{ vehicle.rating_count }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">OWNER</div>
              <div class="col-6">
                <router-link
                class="owner-btn"
              :to="'/drivers/profile/'+vehicle.owner_id"
              
            >View</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" >
      <div class="col-4" v-if="vehicle && vehicle.make">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">Vehicles Maker</div>
            </div>
            <div class="row">
              <div class="col-6">Manufacture</div>
              <div class="col-6">
                {{ vehicle.make.name }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Model</div>
              <div class="col-6">
                {{ vehicle.model.name }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Status</div>
              <div class="col-6">
                {{ vehicle.status==1?'Active':'In-Active' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4" v-if="vehicle && vehicle.current_ad">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">Active AD Info</div>
            </div>
            <div class="row">
              <div class="col-6">Flat rate</div>
              <div class="col-6">
                {{ vehicle.current_ad.campaign.flat_rate }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Sticker Type</div>
              <div class="col-6">
                {{ vehicle.current_ad.campaign.sticker_type }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">Status</div>
              <div class="col-6">
                {{ vehicle.current_ad.campaign.status==2?'Started Wrapping':vehicle.current_ad.campaign.status==1?'Assigned':vehicle.current_ad.campaign.status==3?'Active':"Expired" }}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { vehicle } from "@/config/api/vehicles";
export default {
  props: ["id"],
  components: {
    Layout,
  },
  data() {
    return {
      isBusy: true,
      vehicle: undefined,
    };
  },
  mounted() {
    this.loadVehicleInfo();
  },
  methods: {
    loadVehicleInfo() {
      this.isBusy = true;
      let api = { ...vehicle.vehicle.get };
      api.url += this.id;
      this.generateAPI(api)
        .then((res) => {
          console.log(res);
          this.vehicle = res.data.vehicle;

          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err);
        });
      console.log("Load driver profile!");
    },
  },
};
</script>

<style>
.owner-btn{
  background-color: black;
  padding: 2px 12px;
  border-radius: 6px;
  color: white;
}
.owner-btn:hover{
  color: gray;
}
</style>